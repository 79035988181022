
<template lang="pug">
v-card(light, flat)
    v-row
        v-spacer
        v-col.text-center
            .text-h4 Advanced Analytics
        v-spacer
    v-row(v-if= '!hasAnalytics')
        v-col.text-center
            v-card
                v-card-text
                    v-icon(color='brand', size='64') real_estate_agent
                    br
                    span.font-weight-bold Contact Dorsett Controls to explore your options for Advanced Analytics
                    br
                    .text-h6.brand--text Sales
                    span.font-weight-bold Email
                    br
                    a.brand--text(:href='`mailto:${sales.email}`', target='_blank') {{ sales.email }}
                    br
                    br
                    span.font-weight-bold Phone
                    br
                    a.brand--text(:href='`tel:${dorsett.number}`', target='_blank') {{ dorsett.number }}

    v-row(justify='center', v-if='hasAnalytics', )
            v-col.text-center.flex-shrink-1.flex-grow-0
                v-card(width='300', height='200', @click='navigateToFlow')
                    v-card-text
                        v-icon(color='brand', size='64') query_stats
                        br
                        .text-h6.brand--text Flow
                        span Analyze and Predict Flow
            v-col.text-center.flex-shrink-1.flex-grow-0
                v-card(width='300', height='200')
                    v-card-text
                        v-icon(color='brand', size='64') show_chart
                        br
                        .text-h6.brand--text Power
                        span Power Usage
            v-col.text-center.flex-shrink-1.flex-grow-0
                v-card(width='300', height='200')
                    v-card-text
                        v-icon(color='brand', size='64') bubble_chart
                        br
                        .text-h6.brand--text Data Analysis
                        span Advanced data analysis tools
</template>

<script>

export default {
    data () {
        return {
            dorsett: {
                name: 'Dorsett Controls',
                address: '486 N. Patterson Ave. Suite 301',
                city: 'Winston Salem',
                state: 'NC',
                zip: '27101-4264',
                number: '1-855-DT SCADA (387-2232)',
                email: 'info@dorsettcontrols.com',
                fax: '(336) 679-8811'
            },
            training: {
                email: 'training@dorsettcontrols.com'
            },
            support: {
                email: 'support@dorsettcontrols.com'
            },
            sales: {
                email: 'sales@dorsettcontrols.com'
            }
        };
    },
    computed: {
        hasAnalytics () {
            const customerData = this.$store.state.customerData;
            if( customerData ) {
                return (customerData.customersConfig.hasAnalytics === 1);
            }
            else {
                return false;
            }
        }
    },
    methods: {
        navigateToFlow () {
            // customer/analytics
            this.$router.push('/customer/analytics/flow')
                .then(() => {
                    console.log('hey ok', this.$router.currentRoute );
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
};
</script>
